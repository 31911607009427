<template>
  <div v-if="isAddMode || isEditMode" class="actions-container">
    <input v-model="userCode"
           :disabled="isEditMode"
           placeholder="Matricule"
           type="text"
           @blur="checkUser">
    <input v-model="lastName" disabled placeholder="Nom" type="text">
    <input v-model="firstName" disabled placeholder="Prénom" type="text">
    <div class="select">
      <select v-model="selectedHabilitation">
        <option v-for="habilitation in habilitationsList"
                :key="habilitation.id"
                :value="habilitation.id">
          {{ habilitation.label }}
        </option>
      </select>
    </div>
    <div class="buttons-container">
      <button class="cancel" @click="cancel"> Annuler</button>
      <button :disabled="!canSubmit" class="success" @click="createOrUpdateHabilitation">
        <i class="fa fa-plus" /> {{ buttonLabel }}
      </button>
    </div>
  </div>
</template>

<script>
  import apis from '@/apis'
  import i18n from '../grant-access.i18n'
  import messageService from '@/services/messaging.service'
  import chipmunkService from '@/services/chipmunk.service'

  export default {

    props: {
      isAddMode: {
        type: Boolean,
        default: false
      },
      isEditMode: {
        type: Boolean,
        default: false
      },
      isActionsView: {
        type: Boolean,
        default: false
      },
      selectedItem: {
        type: Object,
        default: () => {}
      },
      context: {}
    },

    data() {
      return {
        userCode: '',
        firstName: '',
        lastName: '',
        selectedHabilitation: 2,
        rolesList: [],
        actionsList: []

      }
    },

    computed: {
      buttonLabel() {
        return this.isAddMode ? i18n.buttons.add : i18n.buttons.edit
      },

      canSubmit() {
        return this.firstName.length > 0 && this.lastName.length > 0 && this.selectedHabilitation != null
      },

      habilitationsList() {
        const habilitationsList = []
        if (this.isActionsView) {
          this.actionsList.forEach(action => {
            if (action.isVisible) {
              let line = {}
              line.id = action.sid
              line.label = action.action
              habilitationsList.push(line)
            }
          })
        } else {
          this.rolesList.forEach(role => {
            if (role.isVisible) {
              let line = {}
              line.id = role.sid
              line.label = role.role
              habilitationsList.push(line)
            }

          })
        }
        return habilitationsList
      }
    },

    watch: {
      userCode(val) {
        if (val.length >= 10) {
          this.searchUser(val)
        } else {
          this.firstName = ''
          this.lastName = ''
        }
      },
      selectedItem(val) {
        if (val) {
          this.userCode = this.selectedItem['Matricule']
          this.firstName = this.selectedItem.item.firstName
          this.lastName = this.selectedItem.item.lastName
          this.selectedHabilitation = this.selectedItem.item.id
        }
      },
      isAddMode(val) {
        if (val) this.clearUser()
      }
    },

    created() {
      this.getHabilitationsList()
    },

    methods: {
      async getHabilitationsList() {
        this.rolesList = await chipmunkService.getRolesList('role')
        this.actionsList = await chipmunkService.getRolesList('action')
      },

      async searchUser(userCode) {
        try {
          let { data } = await apis.users.get(`_search/?uids=${ userCode }`)
          data = data?.[0]
          if (data.givenName && data.iirBirthname) {
            this.firstName = data.givenName
            this.lastName = data.iirBirthname
          }
        } catch (err) {
          const msg = `l'identifiant utilisateur que vous avez saisi (${ this.userCode }) n'existe pas `
          messageService.showError(msg)
          this.firstName = ''
          this.lastName = ''
          this.selectedHabilitation = null
        }
      },

      clearUser() {
        this.userCode = ''
        this.firstName = ''
        this.lastName = ''
        this.selectedHabilitation = null
      },

      async createOrUpdateHabilitation() {
        if (this.checkExistingRole()) {
          let msg = ''
          if (this.isActionsView) {
            msg = `L'action sélectionnée est déjà attribuée à l'utilisateur ${ this.userCode }`
          } else {
            msg = `Le role sélectionné est déjà attribué à l'utilisateur ${ this.userCode }`
          }
          messageService.showError(msg)
          return
        }

        if (this.isAddMode) this.$emit('clearSelectedItem')

        try {
          await chipmunkService.saveOrUpdate(this.selectedHabilitation, this.selectedItem, this.isActionsView, this.isAddMode, this.userCode)
          this.context.isAddMode = false
          this.context.isEditMode = false
          await this.context.getHabilitations()
          this.clearUser()
        } catch (err) {
          console.log(err)
        }
      },

      checkExistingRole() {
        return this.context.habilitations.values.find(habilitation => {
          if (this.isAddMode) {
            return habilitation.item.userCode === this.userCode &&
              habilitation.item.id === this.selectedHabilitation.toString()
          } else {
            return habilitation.item.userCode === this.selectedItem.item.userCode &&
              habilitation.item.id === this.selectedHabilitation.toString()
          }
        })
      },

      cancel() {
        this.context.cancel()
      },

      getIdFromLabel(value, array) {
        return array.find(object => {
          return Object.keys(object).find(key => object[key] === value)
        })
      },

      checkUser() {
        if (this.userCode < 10 || this.firstName === '') {
          const msg = `l'identifiant utilisateur que vous avez saisi (${ this.userCode }) n'existe pas `
          messageService.showError(msg)
        }
      }
    }
  }
</script>

<style lang="scss" scoped>
  @import "~@/sass/main";

  .actions-container {

    input[type=text], .select, select {
      background-color: rgba($search-color, 0.1);
      border: none;
      outline: none;
      padding: 0.5em 1em;
      flex: auto;
      border-radius: 4px;
      font-size: 0.8em;

      select {
        width: 100%;
        background: none;
        padding: 0;
        cursor: pointer;
        font: inherit;
      }
    }

    .buttons-container {
      flex: 100%;
      text-align: right;
      margin-top: 5px;
    }

    button {
      border: none;
      outline: none;
      color: white;
      padding: 0.5em 1em;
      text-align: center;
      font-size: 0.8em;
      margin: 4px 0px;
      border-radius: 3em;
      cursor: pointer;

      i {
        margin-right: 2px;
        font-size: 0.8em;
      }

      &:disabled {
        background-color: #CCCCCC !important;
        color: #666666;
      }

      &.success {
        background-color: $current-selection-color;
      }

      &.error {
        background-color: $message-error;
      }

      &.cancel {
        background: none;
        color: $search-color;
      }
    }
  }

</style>
